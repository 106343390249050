import React, { lazy, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import dotenv from 'dotenv';
import { createCheckoutSession } from './services/createCheckoutSession';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Spin } from 'antd';
const LandingPage = lazy(() => import('./components/LandingPage'));
const SuccessPage = lazy(() => import('./components/SuccessPage'));
const CancelPage = lazy(() => import('./components/CancelPage'));
dotenv.config({ path: '../.env'});
const pkToUse = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_PK_TEST : process.env.REACT_APP_STRIPE_PK_LIVE;
const stripe =  window.Stripe(pkToUse);

function App() {
  const renderLoader = () => <Spin size='large' />
  return (
    <Router>
      <Switch>
        <Suspense fallback={renderLoader()}>
          <Route exact path='/'>
              <LandingPage />
          </Route>
          <Route path='/success'>
              <SuccessPage />
          </Route>
          <Route path='/cancel'>
              <CancelPage />
          </Route>
        </Suspense>
      </Switch>
    </Router>
  );
}

export default App;
