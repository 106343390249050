const axios = require('axios');

export async function createCheckoutSession({ price, associatedCall }) {
  if (!price) {
    throw new Error('Please provide a price parameter to createCheckoutSession');
  }
  if (!associatedCall) {
    throw new Error('Please provide an associatedCall parameter to createCheckoutSession');
  }
  try {
    const createResults = await axios({
      method: 'POST',
      url: 'https://us-central1-cloudpark-pil-test.cloudfunctions.net/createCheckoutSession',
      data: {
        price,
        associatedCall,
        test: process.env.NODE_ENV === 'development' ? true : false
      }
    });
    if (createResults && createResults.data && createResults.data.id) {
      return { success: true, sessionId: createResults.data.id };
    } else {
      throw new Error('createResults are malformed.');
    }
  } catch (error) {
    return { success: false, error };
  }
}